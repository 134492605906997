import { render, staticRenderFns } from "./PagePreferences.vue?vue&type=template&id=90e60850&scoped=true&"
import script from "./PagePreferences.vue?vue&type=script&lang=js&"
export * from "./PagePreferences.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90e60850",
  null
  
)

export default component.exports